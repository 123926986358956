import { FormattedMessage } from 'react-intl'
import s from './NotFoundPage.module.scss';
import IconSuccess from "@/shared/ui/Icons/IconNotFound";

const NotFoundPage = () => {
  return (
    <div className={s.root}>
        <IconSuccess className="mb-6" width="auto" height="auto" />
        <h2 className="m-0 text-center mb-4 text-white text-2xl font-medium">
          <FormattedMessage id='meta.404.title' />
        </h2>
        <p className="text-white text-xs font-medium">
          <FormattedMessage id='meta.404.desc' />
        </p>
    </div>
  );
}

export default NotFoundPage;
