import Header from '@/features/Header';
import MainLayout from '@/shared/layouts/MainLayout';
import NotFoundPage from "@/shared/layouts/NotFoundPage";
import { SEO } from '@/processes/Router';

export default function NotFound() {
  return (
    <>
      <SEO metaKey="404"/>
      <div className="flex flex-col h-screen">
        <Header/>
        <MainLayout className="grow flex items-center justify-center">
          <NotFoundPage />
        </MainLayout>
      </div>
    </>
  );
}
