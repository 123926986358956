const IconSuccess = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="396"
      height="190"
      fill="none"
      viewBox="0 0 396 190"
      {...props}
    >
      <g filter="url(#filter0_dddd_3986_142191)">
        <path
          fill="url(#paint0_radial_3986_142191)"
          d="M129.904 118.4c.56 0 .84 0 1.054.109.188.096.341.249.437.437.109.214.109.494.109 1.054v22.4c0 .56 0 .84-.109 1.054a1.003 1.003 0 01-.437.437c-.214.109-.494.109-1.054.109h-11.8c-.56 0-.84 0-1.054.109a1.003 1.003 0 00-.437.437c-.109.214-.109.494-.109 1.054v20.6c0 .56 0 .84-.109 1.054a1.003 1.003 0 01-.437.437c-.214.109-.494.109-1.054.109h-24.6c-.56 0-.84 0-1.054-.109a1.002 1.002 0 01-.437-.437c-.11-.214-.11-.494-.11-1.054v-20.6c0-.56 0-.84-.108-1.054a1 1 0 00-.437-.437c-.214-.109-.494-.109-1.054-.109h-63c-.56 0-.84 0-1.054-.109a1.002 1.002 0 01-.437-.437c-.11-.214-.11-.494-.11-1.054v-23.613c0-.144 0-.216.011-.287a.992.992 0 01.045-.184c.023-.068.056-.132.122-.26l45.976-89.388c.163-.315.244-.473.363-.588a1 1 0 01.371-.226c.157-.054.334-.054.69-.054h26.006c.865 0 1.298 0 1.557.182a1 1 0 01.414.678c.045.314-.153.7-.547 1.47L53.497 116.07c-.395.77-.592 1.155-.548 1.468a1 1 0 00.415.679c.259.182.692.182 1.557.182h32.183c.56 0 .84 0 1.054-.109a1 1 0 00.437-.437c.109-.214.109-.494.109-1.054v-30c0-.56 0-.84.109-1.054a1 1 0 01.437-.437c.214-.109.494-.109 1.054-.109h24.6c.56 0 .84 0 1.054.109.188.096.341.249.437.437.109.214.109.494.109 1.054v30c0 .56 0 .84.109 1.054.096.188.249.341.437.437.214.109.494.109 1.054.109h11.8z"
        ></path>
        <path
          fill="url(#paint1_radial_3986_142191)"
          d="M197.32 170.6c-17.467 0-31.2-6.6-41.2-19.8-9.867-13.333-14.8-31-14.8-53s4.933-39.6 14.8-52.8c10-13.333 23.733-20 41.2-20 17.6 0 31.333 6.667 41.2 20 9.866 13.2 14.8 30.8 14.8 52.8 0 22-4.934 39.667-14.8 53-9.867 13.2-23.6 19.8-41.2 19.8zm-21.2-38.6c4.8 7.733 11.866 11.6 21.2 11.6 9.333 0 16.4-3.933 21.2-11.8 4.933-7.867 7.4-19.2 7.4-34 0-14.933-2.467-26.333-7.4-34.2-4.8-7.867-11.867-11.8-21.2-11.8-9.334 0-16.4 3.933-21.2 11.8-4.8 7.867-7.2 19.267-7.2 34.2 0 14.933 2.4 26.333 7.2 34.2z"
        ></path>
        <path
          fill="url(#paint2_radial_3986_142191)"
          d="M371.896 118.4c.56 0 .84 0 1.054.109.188.096.341.249.437.437.109.214.109.494.109 1.054v22.4c0 .56 0 .84-.109 1.054a1.003 1.003 0 01-.437.437c-.214.109-.494.109-1.054.109h-11.8c-.56 0-.84 0-1.054.109a1.003 1.003 0 00-.437.437c-.109.214-.109.494-.109 1.054v20.6c0 .56 0 .84-.109 1.054a1.003 1.003 0 01-.437.437c-.214.109-.494.109-1.054.109h-24.6c-.56 0-.84 0-1.054-.109a1.003 1.003 0 01-.437-.437c-.109-.214-.109-.494-.109-1.054v-20.6c0-.56 0-.84-.109-1.054a1.003 1.003 0 00-.437-.437c-.214-.109-.494-.109-1.054-.109h-63c-.56 0-.84 0-1.054-.109a1.003 1.003 0 01-.437-.437c-.109-.214-.109-.494-.109-1.054v-23.613c0-.144 0-.216.01-.287a.97.97 0 01.045-.184c.023-.068.056-.132.122-.26l45.977-89.388c.162-.315.243-.473.362-.588a1.01 1.01 0 01.371-.226c.157-.054.335-.054.689-.054h26.007c.865 0 1.298 0 1.557.182.226.16.376.405.415.678.044.314-.153.7-.548 1.47l-44.014 85.941c-.394.77-.592 1.155-.548 1.468.039.274.189.52.415.679.259.182.692.182 1.557.182h32.183c.56 0 .84 0 1.054-.109.188-.096.341-.249.437-.437.109-.214.109-.494.109-1.054v-30c0-.56 0-.84.109-1.054.096-.188.249-.341.437-.437.214-.109.494-.109 1.054-.109h24.6c.56 0 .84 0 1.054.109.188.096.341.249.437.437.109.214.109.494.109 1.054v30c0 .56 0 .84.109 1.054.096.188.249.341.437.437.214.109.494.109 1.054.109h11.8z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_dddd_3986_142191"
          width="394.411"
          height="189.02"
          x="0.795"
          y="0.848"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="-0.045"></feOffset>
          <feGaussianBlur stdDeviation="0.427"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.211765 0 0 0 0 0 0 0 0 0 1 0 0 0 0.24 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3986_142191"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="-0.199"></feOffset>
          <feGaussianBlur stdDeviation="0.884"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.211765 0 0 0 0 0 0 0 0 0 1 0 0 0 0.182222 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_3986_142191"
            result="effect2_dropShadow_3986_142191"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="-0.488"></feOffset>
          <feGaussianBlur stdDeviation="1.764"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.211765 0 0 0 0 0 0 0 0 0 1 0 0 0 0.145778 0"></feColorMatrix>
          <feBlend
            in2="effect2_dropShadow_3986_142191"
            result="effect3_dropShadow_3986_142191"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="-2.442"></feOffset>
          <feGaussianBlur stdDeviation="10.855"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.211765 0 0 0 0 0 0 0 0 0 1 0 0 0 0.0577778 0"></feColorMatrix>
          <feBlend
            in2="effect3_dropShadow_3986_142191"
            result="effect4_dropShadow_3986_142191"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect4_dropShadow_3986_142191"
            result="shape"
          ></feBlend>
        </filter>
        <radialGradient
          id="paint0_radial_3986_142191"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 319 -411.598 0 89 -116.5)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.316" stopColor="#E9F5FF"></stop>
          <stop offset="1" stopColor="#C9C8FF"></stop>
        </radialGradient>
        <radialGradient
          id="paint1_radial_3986_142191"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 319 -411.598 0 89 -116.5)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.316" stopColor="#E9F5FF"></stop>
          <stop offset="1" stopColor="#C9C8FF"></stop>
        </radialGradient>
        <radialGradient
          id="paint2_radial_3986_142191"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 319 -411.598 0 89 -116.5)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.316" stopColor="#E9F5FF"></stop>
          <stop offset="1" stopColor="#C9C8FF"></stop>
        </radialGradient>
      </defs>
    </svg>
  )
}

export default IconSuccess;
